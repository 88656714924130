<template>
  <layout name="EmptyLayout">
    <div class="uk-container">
      <div class="uk-text-left">
        <a href=""  style="position:absolute; top:20px; left: 20px; padding:5px" @click.prevent="goBack()">
          <span class="uk-margin-small-right" uk-icon="icon: sign-out; ratio:2"></span>
        </a>
      </div>
      <div 
        class="uk-card uk-card-body"
        uk-scrollspy="target: > i; cls: uk-animation-shake; delay: 500; offset-top: 2000;"
      >
        <i uk-icon="icon: warning; ratio: 12" class="uk-text-muted"></i>
        <h1 class="uk-card-title">{{ $t('Generic.Errors.AccessDeniedTitle') }}</h1>
        <p class="uk-card-body uk-text-lighter">{{ $t('Generic.Errors.AccessDeniedMessage') }}</p>
        <button class="uk-button uk-button-primary" @click="goHome()">{{ $t('Sidebar.Dashboard')}}</button>
      </div>
    </div>
  </layout>
</template>

<script>
import Layout from '@/layouts/Layout';
import { mapState} from 'vuex'
export default {
  name: 'AccessDenied',
  components: {
    Layout
  },
  computed: {    
    ...mapState({
      org: state => state.venom.org || {}
    })
    },
  methods: {
    goBack () {
        this.$router.replace({ name: 'Login'})
    },
    goHome () {
      if (this.org?.id) {
        this.$router.replace({ name: "Dashboard", params: { id:  this.org.id }})
      } else {
        this.$router.replace({ name: 'Login'})
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.uk-container {
  height: 100vh;
  z-index: 10;
}
.uk-card {
  margin-top: 5%;
}
h1 {
  font-size: 32px;
}
i {
  z-index: 1;
}
</style>
